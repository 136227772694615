import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./style.css";
import Logo from "./Logo";

const Menu = ({ menuItems }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleMenuClick = (path) => {
    if (path === "logout") {
      localStorage.removeItem("userId");
      navigate("/login"); 
    } else {
      navigate(path); 
    }
    setMenuOpen(false);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className='menu-container'>
      <Logo />
      <div className='association-name'>
        Assalatur Rahman Islamic Association
      </div>
      <div className='menu-toggle'>
        <button className='menu-button' onClick={toggleMenu}>
          ☰
        </button>
        {menuOpen && (
          <div className='menu' ref={menuRef}>
            <ul>
              {menuItems.map((item, index) => (
                <li key={index}>
                  <button
                    className='menu-item'
                    onClick={() => handleMenuClick(item.path)}
                  >
                    {item.label}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default Menu;
