import axios from "axios";
// const apiBaseUrl = "http://192.168.1.104:3000/api";
//const apiBaseUrl = "http://localhost:3000/api";
const apiBaseUrl = process.env.REACT_APP_SERVER_URL;


const getRequest = async (route) => {
  try {
    const result = await axios.get(`${apiBaseUrl}/${route}`);
    return result;
  } catch (error) {
    console.error("GET Request Error:", error);
    return { error: "An error occurred while making the GET request." };
  }
};


const postRequest = async (route, body) => {
  try {
    const result = await axios.post(`${apiBaseUrl}/${route}`, body)
    return result  //returns result, this function (number 2) is complete and now goes to const
    //data line to run the rest of the 1st function which is in apiClient.js
  } catch (error) {
    console.error(error);
    return error
  }
  //data line to run the rest of the 1st function
};

const patchRequest = async (route, body) => {
  try {
    const result = await axios.patch(`${apiBaseUrl}/${route}`, body);
    return result;
  } catch (error) {
    console.error("PATCH Request Error:", error);

    // Optionally, you can return an error object or re-throw the error to handle it in the caller function
    if (error.response) {
      // Server responded with a status other than 200 range
      console.error("Response data:", error.response.data);
      console.error("Response status:", error.response.status);
      console.error("Response headers:", error.response.headers);
      return error.response;
    } else if (error.request) {
      // Request was made but no response received
      console.error("Request data:", error.request);
    } else {
      // Something happened in setting up the request
      console.error("Error message:", error.message);
    }
    
    throw error; // Re-throw the error for further handling
  }
};


export { getRequest, postRequest, patchRequest };
