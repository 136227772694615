import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { postRequest } from "../apiUser";
import { useState } from "react";
import Menu from "./Menu";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./style.css";

export default function Login() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    phone: "",
    password: "",
  });

  const [errors, setErrors] = useState({});

  const handleValidation = () => {
    const newErrors = {};

    if (!formData.email && !formData.phone) {
      newErrors.contact = "Either email or phone number is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!handleValidation()) {
      toast.error("Please enter all required fields");
      return;
    }

    try {
      setIsLoading(true);
      const data = await postRequest("loginUser", {
        user_emailaddress: formData.email,
        user_phonenumber: formData.phone,
        user_password: formData.password,
      });

      if (data.data && data.data.user_id) {
        localStorage.setItem("userId", data.data.user_id);
        navigate("/events");
      } else {
        toast.error(data.response.data.message);
      }
    } catch (error) {
      console.error("Login Error:", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const loginMenuItems = [
    { label: "Home", path: "/" },
    { label: "Sign Up", path: "/signup" },
  ];

  useEffect(() => {
    const checkLoggedUser = async () => {
      const storedUserId = localStorage.getItem("userId");
      if (storedUserId) {
        navigate("/events");
      }
    };

    checkLoggedUser();
  }, [navigate]);

  return (
    <div className='login-container'>
      <ToastContainer />
      <Menu menuItems={loginMenuItems} />
      <div className='form-container'>
        <h3>Log in</h3>
        <form onSubmit={handleSubmit}>
          <div className='form-group'>
            <label>Email</label>
            <input
              name='email'
              onChange={handleChange}
              className='input'
              value={formData.email}
              type='email'
            />
            {errors.email && <p className='error-message'>{errors.email}</p>}
            OR
            <label>Phone</label>
            <input
              name='phone'
              onChange={handleChange}
              className='input'
              value={formData.phone}
              type='phone'
            />
            {errors.phone && <p className='error-message'>{errors.phone}</p>}
            <label>Password</label>
            <input
              name='password'
              onChange={handleChange}
              className='input'
              value={formData.password}
              type='password'
            />
            {errors.password && (
              <p className='error-message'>{errors.password}</p>
            )}
          </div>
          <div className='button-container'>
            <button disabled={isLoading} className='button' type='submit'>
              {isLoading ? "Signing in..." : "SIGN IN"}
            </button>
          </div>
        </form>
        Don't have an account? <Link to='/signup'>Sign Up</Link>
      </div>
    </div>
  );
}
