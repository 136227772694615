// src/components/Logo.js
import React from 'react';
import logo from '../ARIA-LOGO4.png'; 

const Logo = () => {
  return (
    <img src={logo} alt="Logo" style={{ width: '200px', height: 'auto' }} />
  );
};

export default Logo;
