import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Homepage from "./components/Homepage";
import Signup from "./components/Signup";
import Login from "./components/Login";
import AllEvents from "./components/AllEvents";
import Account from "./components/Account";
// import Welcome from './components/Welcome';

import React from "react";
import PrivateRoute from "./components/PrivateRoute";

//add router/routes below
function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path='/' element={<Homepage />} />
          <Route path='/signup' element={<Signup />} />
          <Route path='/login' element={<Login />} />
          <Route
            path='/events'
            element={
              <PrivateRoute>
                <AllEvents />
              </PrivateRoute>
            }
          />
          <Route
            path='/account'
            element={
              <PrivateRoute>
                <Account />
              </PrivateRoute>
            }
          />
          {/* <Route path ="/welcome" element={<Welcome/>}/> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
