import React, { useState, useEffect } from "react";
import Menu from "./Menu";
import { getRequest, postRequest } from "../apiUser";
import "./style.css";
import { formatDate, formatTime } from "../utils/dateTimeFormatter";

export default function AllEvents() {
  const [events, setEvents] = useState([]);
  const [clockedInEvents, setClockedInEvents] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState(null);
  const [filter, setFilter] = useState("current");

  const fetchUserId = () => {
    const storedUserId = localStorage.getItem("userId");
    if (storedUserId) {
      setUserId(storedUserId);
    } else {
      setError("User not logged in.");
    }
  };

  const getEvents = async () => {
    try {
      setLoading(true);
      const response = await getRequest("getEvents");

      if (response.data && Array.isArray(response.data.events)) {
        setEvents(response.data.events);
      } else {
        throw new Error("Unexpected response format");
      }

      if (userId) {
        const clockedInResponse = await getRequest(
          `getUserClockedEvents/${userId}`
        );
        if (clockedInResponse.data && Array.isArray(clockedInResponse.data)) {
          setClockedInEvents(
            clockedInResponse.data.map((event) => event.event_id)
          );
        } else {
          setClockedInEvents([]);
        }
      }
    } catch (error) {
      console.error("Failed to get events:", error);
      setError("Failed to load events. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserId();
  }, []);

  useEffect(() => {
    if (userId) {
      getEvents();
    }
  }, [userId]);

  const handleClockIn = async (eventId) => {
    const clockinTimestamp = new Date().toISOString();

    if (!userId || !eventId) {
      setError("Missing user or event information.");
      return;
    }

    try {
      const response = await postRequest("clockin", {
        user_id: userId,
        event_id: eventId,
        clockin_timestamp: clockinTimestamp,
      });
      console.log("Clock-in Response:", response);
      setClockedInEvents((prev) => [...prev, eventId]);
      setError("");
    } catch (err) {
      console.error("Failed to clock in:", err);
      setError("Failed to clock in. Please try again later.");
    }
  };

  const filterEvents = (events) => {
    const now = new Date();
    const todayStart = new Date(now.setHours(0, 0, 0, 0));
    const todayEnd = new Date(now.setHours(23, 59, 59, 999));

    switch (filter) {
      case "past":
        return events.filter(
          (event) => new Date(event.event_date) < todayStart
        );
      case "future":
        return events.filter((event) => new Date(event.event_date) > todayEnd);
      case "current":
      default:
        return events.filter(
          (event) =>
            new Date(event.event_date) >= todayStart &&
            new Date(event.event_date) <= todayEnd
        );
    }
  };

  const isEventTimeValid = (event) => {
    const now = new Date();
    const [eventHours, eventMinutes] = event.event_time.split(":").map(Number);
    const eventStartTime = new Date();
    eventStartTime.setHours(eventHours, eventMinutes, 0, 0);
    
    return now >= eventStartTime;
  };

  const filteredEvents = filterEvents(events);

  const errorMessage = () => (
    <div
      className='error'
      style={{
        display: error ? "" : "none",
      }}
    >
      <h1>{error}</h1>
    </div>
  );

  const eventsMenuItems = [
    { label: "Home", path: "/" },
    { label: "Account Settings", path: "/account" },
    { label: "Log out", path: "logout" },
  ];

  return (
    <div className='event-container'>
      <Menu menuItems={eventsMenuItems} />
      <div className='filter-buttons'>
        <button onClick={() => setFilter("past")}>Past Events</button>
        <button onClick={() => setFilter("current")}>Events For Today</button>
        <button onClick={() => setFilter("future")}>Future Events</button>
      </div>
      <div>
        <div className='messages'>{errorMessage()}</div>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <div className='event-list'>
            {filter === "past" && <h2>PAST EVENTS</h2>}
            {filter === "current" && <h2>EVENTS FOR TODAY</h2>}
            {filter === "future" && <h2>FUTURE EVENTS</h2>}
            {filteredEvents.length === 0 ? (
              <p style={{ marginTop: 25, fontWeight: "bold" }}>
                No events to display
              </p>
            ) : (
              <div className='card-container'>
                {filteredEvents.map((event) => {
                  const isPast =
                    new Date(event.event_date) < new Date().setHours(0, 0, 0, 0);
                  const isCurrent =
                    new Date(event.event_date) >=
                      new Date().setHours(0, 0, 0, 0) &&
                    new Date(event.event_date) <=
                      new Date().setHours(23, 59, 59, 999);

                  return (
                    <div className='card' key={event.event_id}>
                      <div className='card-body'>
                        <h5 className='card-title'>{event.event_name}</h5>
                        <p className='card-text'>
                          Date: {formatDate(event.event_date)}
                        </p>
                        <p className='card-text'>
                          Time: {formatTime(event.event_time)}
                        </p>
                        {isPast && (
                          <p className='card-text'>
                            Status:{" "}
                            {clockedInEvents.includes(event.event_id)
                              ? "ATTENDED"
                              : "NOT ATTENDED"}
                          </p>
                        )}
                        {isCurrent && isEventTimeValid(event) && (
                          <button
                            className='clock-in-button'
                            onClick={() => handleClockIn(event.event_id)}
                            disabled={clockedInEvents.includes(event.event_id)}
                          >
                            {clockedInEvents.includes(event.event_id)
                              ? "ATTENDED"
                              : "ATTEND"}
                          </button>
                        )}
                        {isCurrent && !isEventTimeValid(event) && (
                          <p className='card-text'>
                            Clock-in is not allowed until the event time.
                          </p>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
